globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"ilwDE34su0E35EHEdOcde"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

let errorTimeout: NodeJS.Timeout | null = null; // Explicitly declare the type

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://288f9ec7b99117ae2f95ac00e48a183f@o4505843707674624.ingest.us.sentry.io/4508160646840320",
    tracesSampleRate: 1,
    debug: false,

    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && event.event_id) {
        // Prevent showing multiple dialogs in quick succession
        if (!errorTimeout) {
          Sentry.showReportDialog({
            title: "Report an Issue",
            subtitle:
              "Please provide any additional information about the error.",
            eventId: event.event_id,
          });

          // Set a timeout to block other dialogs for 5 seconds
          errorTimeout = setTimeout(() => {
            errorTimeout = null;
          }, 5000); // Adjust this duration as needed
        }
      }
      return event;
    },
  });
}
